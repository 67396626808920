import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { alertModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import Button from "../elements/Button";
import { ButtonArea } from "../elements/Elements";

const AlertModal = () => {
  const [modal, setModal] = useRecoilState(alertModalState);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 200);
  };

  const handleConfirm = () => {
    modal?.onConfirm && modal.onConfirm();
    handleClose();
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleConfirm}>
      <Wrapper ref={wrapperRef}>
        <PopupText>
          <h1 dangerouslySetInnerHTML={{ __html: modal?.title }} />
          <p dangerouslySetInnerHTML={{ __html: modal?.text }} />
        </PopupText>
        <ButtonArea>
          <Button onClick={handleConfirm}>확인</Button>
        </ButtonArea>
      </Wrapper>
    </ModalWrapper>
  );
};

export default AlertModal;

const PopupText = styled.div`
  padding: 24px 20px 10px;

  & > h1 {
    font-size: 18px;
    font-weight: 500;
    line-height: 25.2px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 6px;
  }

  & > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    color: rgba(0, 0, 0, 0.6);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 24px 24px 0px 0px;
  position: relative;
  transition: all 0.3s;
  bottom: -100%;
`;
