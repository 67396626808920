import React, { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { toastModalState } from "../../utils/atom";

const ToastModal = () => {
  const [modal, setModal] = useRecoilState(toastModalState);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null && modal) {
      let el: any = wrapperRef.current;

      setTimeout(() => {
        el.style.display = "block";
        setTimeout(() => {
          //@ts-ignore
          el.style.opacity = "1";
        });
      });

      setTimeout(() => {
        try {
          el.style.opacity = "0";

          setTimeout(() => {
            el.style.display = "none";
            setModal(null);
          }, 200);
        } catch (error) {}
      }, 3000);
    }
  }, [wrapperRef, modal]);

  return <Modal ref={wrapperRef}>{modal?.text}</Modal>;
};

export default ToastModal;

const Modal = styled.div`
  position: absolute;
  bottom: 98px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  opacity: 0;
  display: none;

  max-width: calc(100% - 40px);
  width: fit-content;
  padding: 8px 18px;
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  color: white;
  text-align: center;
  transition: all 0.3s;
  border-radius: 8px;
  background: rgba(11, 11, 12, 0.698);
`;
