//IE동작 도움
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import * as ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import App from "./client/App";
import { HelmetProvider } from "react-helmet-async";

import "swiper/css/bundle";
import "react-quill/dist/quill.snow.css";
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://3c0e4e728309642d38c035774e4bb625@o4507456368803840.ingest.de.sentry.io/4507456513441872",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/\api.\dolbau\.kr\/api/, /^https:\/\/dolbau\.kr\//],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

//react 버전 18부터 ReactDom.render 사용 X
//@ts-ignore
const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <RecoilRoot>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </RecoilRoot>
);
