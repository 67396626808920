import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { selectModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import Button from "../elements/Button";
import { ButtonArea } from "../elements/Elements";
import { OptionType } from "../../types/types";

const SelectModal = () => {
  const [modal, setModal] = useRecoilState(selectModalState);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 200);
  };

  const handleConfirm = (select: OptionType) => {
    modal?.onSelect && modal?.onSelect(select);
    handleClose();
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        {modal?.title && <Title>{modal?.title}</Title>}
        <List>
          {modal?.options?.map((data, i) => (
            <div
              key={"select_modal_list" + i}
              onClick={() => handleConfirm(data)}
              className={modal?.value === data?.value ? "active" : ""}
            >
              {data?.text ? data?.text : data?.value}
            </div>
          ))}
        </List>
      </Wrapper>
    </ModalWrapper>
  );
};

export default SelectModal;

const List = styled.div`
  padding: 20px;
  max-height: 220px;
  overflow: auto;

  & > div {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: ${(props) => props.theme.color.gray1};
    border-radius: 15px;
    cursor: pointer;

    &.active {
      background: #f6f6f6;
    }
  }
`;

const Title = styled.div`
  padding: 24px 20px 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;
  color: rgba(0, 0, 0, 0.8);
`;

const Wrapper = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 24px 24px 0px 0px;
  position: relative;
  transition: all 0.3s;
  bottom: -100%;
`;
