import React from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { loadingState } from "../../utils/atom";

const Loading = () => {
  const [modal, setModal] = useRecoilState(loadingState);

  if (modal === null) return null;
  return (
    <Wrapper>
      <img src={require("../../assets/images/loading-image.gif")} />
    </Wrapper>
  );
};

export default Loading;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 150px;
    height: 150px;
    object-fit: contain;
  }
`;
