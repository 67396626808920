import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { twoButtonModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import Button from "../elements/Button";
import { ButtonArea } from "../elements/Elements";

const TwoButtonModal = () => {
  const [modal, setModal] = useRecoilState(twoButtonModalState);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  const OnClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 200);
  };

  const handleClose = () => {
    modal?.onClose && modal?.onClose();
    OnClose();
  };

  const handleConfirm = () => {
    modal?.onConfirm && modal.onConfirm();
    OnClose();
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <PopupText>
          <h1 dangerouslySetInnerHTML={{ __html: modal?.title }} />
          {modal?.text && (
            <p dangerouslySetInnerHTML={{ __html: modal?.text }} />
          )}
        </PopupText>
        <ButtonArea className="flex">
          <Button styleType="line" onClick={handleClose}>
            {modal?.cancleText || "닫기"}
          </Button>
          <Button onClick={handleConfirm}>{modal?.confirmText || "예"}</Button>
        </ButtonArea>
      </Wrapper>
    </ModalWrapper>
  );
};

export default TwoButtonModal;

const PopupText = styled.div`
  padding: 24px 20px 10px;

  & > h1 {
    font-size: 18px;
    font-weight: 500;
    line-height: 25.2px;
    color: rgba(0, 0, 0, 0.8);
  }

  & > p {
    margin-top: 6px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    color: rgba(0, 0, 0, 0.6);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 24px 24px 0px 0px;
  position: relative;
  transition: all 0.3s;
  bottom: -100%;
`;
