import React from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { postModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import DaumPostcode from "react-daum-postcode";

import { ReactComponent as Close } from "../../assets/icons/close-icon.svg";

const PostModal = () => {
  const [modal, setModal] = useRecoilState(postModalState);

  const handleClose = () => {
    setModal(null);
  };

  const onCompletePost = (data: any) => {
    let fullAddr = data.address;
    let extraAddr = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddr += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddr +=
          extraAddr !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddr += extraAddr !== "" ? ` (${extraAddr})` : "";
    }
    const postcode = data?.zonecode; //우편번소
    const address = fullAddr; //주소

    modal?.onClick && modal.onClick(address);
    handleClose();
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="full" onClose={handleClose}>
      <Wrapper>
        <Header>
          <Close onClick={handleClose} />
        </Header>
        <PopupText>
          <DaumPostcode
            style={{
              width: "100%",
              height: "100%",
            }}
            onComplete={onCompletePost}
          />
        </PopupText>
      </Wrapper>
    </ModalWrapper>
  );
};

export default PostModal;

const PopupText = styled.div`
  width: 100%;
  height: calc(100% - 54px);
  padding: 20px;
`;

const Header = styled.div`
  width: 100%;
  height: 54px;
  padding: 0 20px;

  & > svg {
    display: block;
    margin-left: auto;
    width: 24px;
    height: 24px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
`;
