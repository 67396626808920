import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Change,
  Chat,
  Coupon,
  Faq,
  HelpForm,
  HelpIntro,
  Helper,
  Join,
  JoinInfo,
  Kakao,
  Apple,
  Location,
  Login,
  Main,
  MainBanner,
  Matching,
  Modify,
  ModifyCertified,
  Mypage,
  Payment,
  PhoneCertified,
  PhoneLogin,
  Profile,
  Report,
  Review,
  Root,
  Setting,
  Term,
  UsageDetail,
  UsageList,
  Withdrawal,
} from "../pages";
import { SWRConfig } from "swr";
import swrConfig from "../utils/swrConfig";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "../styles/global-styles";
import HelmetComponent from "../components/HelmetComponent";
import WrapperRoute from "../components/routes/WrapperRoute";
import PrivateRoute from "../components/routes/PrivateRoute";
import theme from "../utils/theme";

const App = () => {
  return (
    <SWRConfig value={swrConfig}>
      <ThemeProvider theme={theme}>
        <HelmetComponent />
        <GlobalStyles />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<WrapperRoute element={<Root />} />} />
            {/* 카카로 로그인 */}
            <Route
              path="/app/login/kakao"
              element={<WrapperRoute element={<Kakao />} />}
            />
            <Route
              path="/app/login/apple"
              element={<WrapperRoute element={<Apple />} />}
            />
            {/* 로그인/회원가입 */}
            <Route
              path="/login"
              element={<WrapperRoute element={<Login />} />}
            />
            <Route
              path="/login/phone"
              element={<WrapperRoute element={<PhoneLogin />} />}
            />
            <Route
              path="/login/phone/:phone"
              element={<WrapperRoute element={<PhoneCertified />} />}
            />
            <Route
              path="/join/:phone"
              element={<WrapperRoute element={<Join />} />}
            />
            <Route
              path="/join/:phone/:alarm"
              element={<WrapperRoute element={<JoinInfo />} />}
            />
            <Route
                path="/term/:type"
                element={<WrapperRoute element={<Term />} />}
            />
            <Route path="/faq" element={<WrapperRoute element={<Faq />} />} />
            {/* 메인 */}
            <Route path="/main" element={<PrivateRoute element={<Main />} />} />
            <Route
              path="/main/banner/:id"
              element={<PrivateRoute element={<MainBanner />} />}
            />
            {/* 헬퍼요청 */}
            <Route
              path="/help"
              element={<PrivateRoute element={<HelpIntro />} />}
            />
            <Route
              path="/help/form"
              element={<PrivateRoute element={<HelpForm />} />}
            />
            <Route
              path="/help/form/:id"
              element={<PrivateRoute element={<HelpForm />} />}
            />
            {/* 매칭 */}
            <Route
              path="/matching/:id"
              element={<PrivateRoute element={<Matching />} />}
            />
            <Route
              path="/matching/:id/helper"
              element={<PrivateRoute element={<Helper />} />}
            />
            {/* 결제 */}
            <Route
              path="/matching/:id/:helper/payment"
              element={<PrivateRoute element={<Payment />} />}
            />
            {/* 이용내역 */}
            <Route
              path="/usage"
              element={<PrivateRoute element={<UsageList />} />}
            />
            <Route
              path="/usage/:id"
              element={<PrivateRoute element={<UsageDetail />} />}
            />
            {/* 채팅 */}
            <Route
              path="/chat/:id"
              element={<PrivateRoute element={<Chat />} />}
            />
            {/* 위치 */}
            <Route
              path="/location/:id"
              element={<PrivateRoute element={<Location />} />}
            />
            {/* 리뷰 */}
            <Route
              path="/review/:id"
              element={<PrivateRoute element={<Review />} />}
            />
            {/* 보고서 */}
            <Route
              path="/report/:id"
              element={<PrivateRoute element={<Report />} />}
            />
            {/* 마이페이지 */}
            <Route
              path="/mypage"
              element={<PrivateRoute element={<Mypage />} />}
            />
            <Route
              path="/profile"
              element={<PrivateRoute element={<Profile />} />}
            />
            <Route
              path="/profile/modify"
              element={<PrivateRoute element={<Modify />} />}
            />
            <Route
              path="/profile/modify/:phone"
              element={<PrivateRoute element={<ModifyCertified />} />}
            />
            <Route
              path="/profile/change/:phone"
              element={<PrivateRoute element={<Change />} />}
            />
            <Route
              path="/withdrawal"
              element={<PrivateRoute element={<Withdrawal />} />}
            />
            <Route
              path="/coupon"
              element={<PrivateRoute element={<Coupon />} />}
            />
            <Route
              path="/setting"
              element={<PrivateRoute element={<Setting />} />}
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </SWRConfig>
  );
};

export default App;
