import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { termModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import "../../styles/customTable.css"

import { ReactComponent as Close } from "../../assets/icons/close-icon.svg";

const TermModal = () => {
  const [modal, setModal] = useRecoilState(termModalState);

  const handleClose = () => {
    setModal(null);
  };

  let arr = [
      {
          title: "제 1장 총칙",
          text: `제1조 (목적)
 본 약관은 넥스트도어(회사)가 제공하는 동행서비스, 주거지원서비스, 여가지원서비스의 계약내용, 이용조건 및 절차에 관한 사항과 기타 필요한 사항을 규정함을 목적으로 합니다. (PC통신, 모바일을 이용하는 전자거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.)

제2조 (약관의 효력 및 변경)
① 본 약관의 내용은 돌바U의 홈페이지에 게시하거나, 인쇄된 약관을 회원에게 구두 설명 및 교부함으로써 그 효력이 발생합니다.
② 넥스트도어는 불가피한 사정이 있는 경우, 관계법령을 위배하지 않는 범위에서 본 약관을 임의로 변경할 수 있고 변경된 약관은 제1항과 같은 방법으로 공지함으로써 그 효력이 발생합니다.
③ 회원은 변경된 약관에 동의하지 않을 경우 계약해지를 요청할 수 있으며, 변경된 약관의 효 력은 발생일 이후의 계속적인 본 서비스 이용은 변경된 약관에 동의한 것으로 간주합니다.

제3조 (서비스의 내용 및 변경)
① 넥스트도어는 다음의 서비스를 제공합니다.
② 기본서비스 
가. 병원동행 서비스 : 돌봐U 플랫폼에서 매칭된 헬퍼가 회원 또는 회원의 부모님의 병원방문을 동행하는 서비스이며, 서비스 시작 위치(주로 집)에서부터 서비스 목표 위치(주로 병원)까지 동행하며 왕복하는 일련의 활동을 말합니다. 서비스 시작 위치와 종료 위치는 회원이 정할 수 있습니다.
나. 주거지원 서비스 : 돌봐U 플랫폼에서 매칭된 헬퍼가 회원 또는 회원의 부모님 집에 방문하여 가사일을 돕는 서비스입니다. 대표적인 가사일로는 빨래, 청소, 요리, 심부름 등이 있습니다. 
다. 여가지원 서비스 : 돌봐U 플랫폼에서 매칭된 헬퍼가 회원 또는 회원의 부모님의 여가생활을 돕는 서비스입니다. 대표적인 여가일로는 산책, 쇼핑, 말동무, 등산 등이 있습니다. 
③ 기타 넥스트도어가 정하는 서비스 
④ 넥스트도어는 불가피한 사정이 있는 경우 제공하는 서비스의 내용을 변경할 수 있으며, 이 경우 변경된 서비스의 내용 및 제공일자를 명시하여 그 제공일자 7일 이전부터 공지합니다.

제4조 (서비스의 중단)
① 넥스트도어는 시스템 등 장치의 보수점검·교체 및 고장, 통신의 두절, 기타 불가항력적 사유가 발 생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
② 넥스트도어는 제1항의 사유로 서비스 내용이 변경 또는 중단됨으로 인하여 회원이 입은 손해에 대하여 배상하지 않습니다. 단, 넥스트도어의 고의 또는 중과실이 있는 경우에는 그러하지 아니 합니다.

제5조 (이용 요금, 취소/환불 등)
① 제공된 서비스의 지불 주체는 계약 내용에 따라 회원이며, 회원은 서비스 이외 발생된 비용 일체를 부담합니다(의료비, 약제비, 본인식사 비용, 교통비 등). 
② 서비스 비용은 홈페이지에 게시된 요금을 바탕으로 하나, 변수에 따라서 추가 부과될 수 있 습니다. 병원동행 서비스는 기본 2시간 이상 이용을 원칙으로 합니다. 서비스 요청시 회원은 2시간 이상만큼 사전 결제하고, 추가 진행된 시간만큼 추가결제를 합니다.
③ 헬퍼가 회원이 지정한 출발지에 도착하여 도착을 안내하였음에도 불구하고 회원이 일정시간(30분) 이내에 연락이 되지 않거나 서비스를 취소하는 경우 넥스트도어는 본 조에 명시된 취소 수수료를 부과할 수 있습니다. 
* 취소수수료
<table class="custom-table">
    <tr>
      <th scope="col">취소 주체</td>
      <th scope="col">취소 조건</td>
      <th scope="col">수수료</td>
    </tr>
    <tr>
      <td rowspan="2" style="align-content: center">고객</td>
      <td>서비스 1시간 이내 취소 시</td>
      <td>기본 서비스시간 요금의 50% 청구</td>
    </tr>
    <tr>
      <td>노쇼, 헬퍼 도착 후 취소 시</td>
      <td>기본 서비스시간 요금의 100% 청구</td>
    </tr>
</table>
\t•\t기본서비스시간 : 2시간
④ 병원동행 전문가(헬퍼)가 서비스를 일방적으로 취소할 경우, 서비스 요금 전액을 환불합니다.
⑤ 예상보다 서비스기간이 길어져서 추가요금이 발생한 경우 10분단위로 서비스 금액을 측정하며, 처음 결제했던 신용카드 등의 결제수단에 의해 자동 결제됩니다. 병원동행 서비스의 경우 기본시간 2시간 이후 추가되는 10분 당 3,500원의 서비스 요금이 발생합니다.


제 2장 회원 가입과 서비스 이용
제1조 (회원의 정의)
회원이란 넥스트도어가 정한 양식에 따라 회원정보를 기입한 후 본 약관에 동의한다는 의사표시를 함으로서 회원의 자격을 등록한 자를 말합니다.
제2조 (서비스 가입의 성립)
① 서비스 가입은 이용자의 이용신청에 대한 넥스트도어의 이용승낙과 회원의 약관내용에 대한 동의로 성립됩니다.
② 회원으로 가입하여 서비스를 이용하고자 하는 희망자는 넥스트도어에서 요청하는 개인 신상정보를 제공해야 합니다.
③ 넥스트도어는 다음 각 호에 해당하는 가입신청에 대하여는 승낙하지 않습니다.
- 다른 사람의 명의를 사용하여 신청하였을 때
- 본인의 실명으로 신청하지 않았을 때
- 가입 신청서의 내용을 허위로 기재하였을 때
- 사회의 안녕과 질서 혹은 미풍양속을 저해할 목적으로 신청하였을 때

제3조 (서비스 이용 및 제한)
① 서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.
② 전항의 서비스 이용시간은 시스템 정기점검 등 넥스트도어에서 필요한 경우, 회원에게 사전 통지한 후 제한할 수 있습니다.
③ 서비스 제공 기간은 60일을 초과할 수 없으며, 서비스 예약 지정일은 예약시점부터 60일 이내로 제한합니다.

제3장 서비스 탈퇴 및 이용 제한

제1조 (서비스 탈퇴)

① 회원이 서비스의 탈퇴를 원하면 ‘마이페이지 – 회원정보 – 회원탈퇴’버튼을 눌러서 탈퇴 할 수 있습니다.
② 탈퇴 여부는 기존의 방식으로 로그인 되지 않으면 탈퇴된 것입니다.

제2조 (서비스 이용제한)

넥스트도어는 회원이 다음 사항에 해당하는 행위를 하였을 경우, 사전통지 없이 이용계약을 해지하거나 기간을 정하여 서비스 이용을 중지할 수 있습니다.

가. 공공 질서 및 미풍 양속에 반하는 경우
나. 범죄적 행위에 관련되는 경우
다. 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행할 경우
라. 타인의 ID 및 비밀번호를 도용한 경우
마. 타인의 명예를 손상시키거나 불이익을 주는 경우
바. 같은 사용자가 다른 ID로 이중 등록을 한 경우
사. 서비스에 위해를 가하는 등 건전한 이용을 저해하는 경우
아. 기타 관련 법령이나 넥스트도어에서 정한 이용조건에 위배되는 경우


제 4장 서비스에 관한 책임의 제한

제1조 (서비스에 관한 책임의 제한)

① 넥스트도어는 회원의 진료내용이 서비스 운영자를 제외한 제3자에게 유출되지 않도록 최선을 다 해 보안을 유지하려고 노력합니다. 
② 서비스 사용 시 진행된 내용은 특정 개인을 식별할 수 없는 형태로 서비스 개선을 위한 참 고의 목적으로 사용할 수 있습니다. 
③ 리포트 제공 시 제공되는 의사 소견에 대한 내용은 의사의 구두 발언을 바탕으로 한 헬퍼가 작성한 내용으로, 공식적인 의견이 될 수 없으며, 해당 내용에 대하여 넥스트도어는 일 체의 책임을 지지 않습니다. 
④ 다음과 같은 서비스 신청의 경우에는 신청을 거절할 수 있습니다. 
㉠ 상식에 어긋나는 표현을 사용하여 서비스를 신청하는 경우 
㉡ 진단명을 요구하는 경우 
㉢ 치료비, 검사비, 의약품 가격 등에 대하여 서비스를 신청하는 경우 
㉣ 이밖에 의료행위에 준하는 서비스를 요청하는 경우 
⑤ 넥스트도어에서 제공하는 서비스 정보는 전적으로 이용자의 판단에 따라 이용되는 것으로서, 서 넥스트도어는 정보제공과 관련하여 어떠한 책임도 지지 않습니다.


제 5장 넥스트도어(회사) 및 회원의 의무

제1조 넥스트도어(회사)의 의무

① 넥스트도어는 특별한 사정이 없는 한 회원이 서비스를 이용할 수 있도록 합니다.
② 넥스트도어는 이 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 의무가 있습니다.
③ 넥스트도어는 회원으로부터 소정의 절차에 의해 제기되는 의견에 대해서 적절한 절차를 거쳐 처리하며, 처리시 일정기간이 소요될 경우 회원에게 그 사유와 처리 일정을 알려주어야 합니다.

제2조 회원의 의무

① 회원은 헬퍼가 서비스를 안전하게 제공할 수 있도록 협조하여야 하며, 넥스트도어 회원의 본 약관 위반행위를 발견하여 회원에게 해당 위반행위에 대하여 소명을 요청할 경우 회원은 넥스트도어의 요청에 적극 응하여야 합니다. 
② 회원은 서비스 이용 시 허위 내용을 등록해서는 안되며, 제공한 정보의 내용이 변경되었을 경우, 빠른 시일 내에 이를 알려야 합니다. 알리지 않은 사유로 발생되는 손해나 문제의 책임은 회원에게 있습니다. 
③ 회원은 서비스 이전 중대한 기저질환이나 복용중인 약, 섭취 금지 음식에 대해 사전에 서비스 요청서 작성 시 기재해서 헬퍼에게 알려야 합니다.


제 6 장 병원동행 전문가(헬퍼) 정책

① 병원동행 전문가(헬퍼)는 요양보호사, 간호사, 사회복지사, 간호조무사 중 하나의 자격증을 가진 사람을 활용하며, 이들이 넥스트도어 등 병원동행 관련업체로부터 병원동행 교육을 받으면 병원동행 서비스 공급이 가능토록 합니다.
② 사업 진행상황에 따라 위 1항의 자격증 4가지가 없어도 넥스트도어 등 병원동행 관련업체로부터 병원동행 교육을 이수한다면 병원동행 서비스 공급을 가능토록 할 수 있습니다.
③ 병원동행 전문가(헬퍼)는 회사(넥스트도어)와 따로 계약을 하며, 회사는 사업비와 3.3% 원천징수 차감 후 서비스 금액을 병원동행 전문가(헬퍼)에게 지불합니다.
④ 병원동행 전문가(헬퍼)는 돌바유 플랫폼 내에서 본인이 원하는 서비스에 직접 지원하여 서비스를 공급하고, 서비스 공급한 시간만큼 고객으로부터 비용을 지불 받습니다.


제 7 장 분쟁조정

① 본 이용약관에 규정된 것을 제외하고 발생하는 서비스 이용에 관한 제반문제에 관한 분쟁은 최대한 쌍방합의에 의해 해결하도록 합니다.
② 서비스 이용으로 발생한 분쟁에 대해 소송이 제기될 경우 회사의 소재지를 관할하는 법원을 관할법원으로 합니다.
`,
    },
    {
      title: "돌바유 개인정보처리방침",
      text: `넥스트도어(회사)는 ｢개인정보 보호법｣ 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다. <br/>
제1조(개인정보의 처리목적)
넥스트도어는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 ｢개인정보 보호법｣ 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.<br/>
1. 넥스트도어 서비스 제공 및 운영
회원이 가입한 넥스트도어 서비스 내 상호 식별, 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산, 이용 및 경비 결제, 콘텐츠 제공, 물품 배송 또는 청구서 등 발송, 금융거래 본인 인증 및 금융 서비스(추후 기능 추가 예정), 미수금 추심, 서비스 이용 중 사고 발생 시 사고처리, 회사의 약관 또는 이용 정책의 위반 행위 적발을 위한 기록 조회 및 이와 관련된 사항
2. 회원관리 및 본인확인
서비스 제공, 회원 식별, 본인 확인, 개인 식별, 이용이 제한된 회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인 및 가입 횟수 제한, 분쟁 조정을 위한 기록 보존, 불만 처리 등 민원 처리, 고지 사항 전달, 추후 법정 대리인 본인 확인, 최신 정보 전달, 서비스의 유효성 확인, 접속 빈도 파악, 회원의 서비스 이용에 대한 통계 확인 및 파악 및 이와 관련된 사항
3. 신규 서비스 개발 서비스 개선, 신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공
4. (선택적) 마케팅, 광고에의 활용 고객에게 이벤트 참여 기회 제공 등 마케팅에 이용 또는 고객에게 광고성 정보 제공
5. 사고 발생 시 원인 규명 및 처리를 위한 수집 및 이용
신원확인, 민원사항 확인, 사실조사를 위한 연락통〮지, 처리결과 통보의 목적으로 개인정보를 처리합니다.

제2조(개인정보의 처리 및 보유기간)
1. 넥스트도어는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
2. 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
(1) 회사 내부 방침에 의한 정보 보유
회원 간의 분쟁 및 문제해결을 위한 회원정보 기록 : 분쟁의 소멸 시효 등 도과시까지
탈퇴 회원의 재가입 제한을 위한 회원정보 기록: 3개월
이용 제한 회원 및 부정 회원의 재가입 제한을 위한 회원 정보 기록: 제한 기간에 따름(별도저장)
(2) 관계 법령에 의한 정보 보유
표시/광고에 관한 기록 : 6개월 (「전자상거래 등에서의 소비자보호에 관한 법률」)
계약 또는 청약철회 등에 관한 기록 : 5년 (「전자상거래 등에서의 소비자보호에 관한 법률」)
대금결제 및 재화 등의 공급에 관한 기록 : 5년 (「전자상거래 등에서의 소비자보호에 관한 법률」)
소비자의 불만 또는 분쟁 처리에 관한 기록 : 3년 (「전자상거래 등에서의 소비자보호에 관한 법률」)
로그인 기록 : 3개월 (「통신비밀보호법」)

제3조(개인정보의 제3자 제공)
넥스트도어는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 ｢개인정보 보호법｣ 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
다만, 다음의 경우 주의를 기울여 회원의 개인정보를 제한적으로 공개할 수 있습니다. 돌바유 서비스 이용 도중 매칭된 회원과 헬퍼 상호간 식별을 위해 이름, 프로필 사진, 전화 번호 등이 돌바유 서비스 내의 연결된 회원에게 공개됩니다. 이는 특정이 불가능하여 별도로 고지하여 동의 받지 못함을 양해바랍니다.

제4조(개인정보처리의 위탁)
넥스트도어는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
1. 결제서비스 : 나이스페이먼트 주식회사
넥스트도어는 위탁계약 체결 시 ｢개인정보 보호법｣ 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고있습니다.
2. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.

제5조(정보주체와 법정대리인의 권리·의무 및 행사방법)
1. 회원은 언제든지 등록되어 있는 자신의 개인 정보를 열람하거나 수정할 수 있으며, 회사의 개인정보 처리에 동의하지 않는 경우 동의를 거부하거나 가입 해지(회원탈퇴)를 요청할 수 있습니다. 단, 개인 정보에서 서비스 운영을 위해 확인이 필요한 정보를 변경할 경우에는 회사의 승인이 이루어질 때까지 서비스 이용이 제한될 수 있습니다.
2. 회원의 개인정보 조회 및 수정을 위해서는 마이페이지 내 "회원정보수정"을, 가입해지(동의철회)를 위해서는 "회원탈퇴"를, 각 클릭하여 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보 관리책임자에게 서면 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
3. 회원이 개인정보의 오류에 대한 정정을 요청하신 경우에 회사는 당해 정정을 완료하기 전까지 당해 개인정보를 이용하거나 제3자에게 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리 결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
4. 회사는 회원의 요청에 의해 해지 또는 삭제된 개인정보는 "제2조 개인정보의 처리 및 보유기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.

제6조(처리하는 개인정보 항목)
1. 수집하는 개인정보의 항목
회사는 회원가입, 헬퍼의 신분 확인, 그리고 각종 서비스의 제공을 위해 돌바유 서비스 최초 이용 시 다음과 같은 개인정보를 수집하고 있습니다.
일반 사용자 개인정보 : 이름, 성별, 연락처(휴대전화번호), 생년월일, 통신사업자, 암호화된 이용자 확인값(CI), 중복가입확인정보(DI), 서비스 이용 및 결제내역, 유료 서비스 이용 시 신용카드 정보, 신용카드 번호 일부 등
헬퍼 사용자 개인정보 : 이름, 성별, 연락처(휴대전화번호), 생년월일, 주민등록번호, 통신사업자, 암호화된 이용자 확인값(CI), 중복가입확인정보(DI), 프로필 이미지, 직업, 서비스 지역, 서비스 이용료 정산을 위한 계좌 정보(은행명,예금주명,계좌번호), 자격증, 학생증정보(또는 재학증명서)
기타 정보 : 쿠키정보, 헬퍼 위치 정보

제7조(개인정보의 파기)
1. 넥스트도어는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
2. 개인정보 파기 방법은 다음과 같습니다.
전자적 파일 : 파일 삭제 및 디스크 등의 저장매체 포맷
수기(手記) 문서 : 분쇄하거나 소각

제8조(개인정보의 안전성 확보조치)
회원은 언제든지 등록되어 있는 자신의 개인 정보를 열람하거나 수정할 수 있으며, 회사의 개인정보 처리에 동의하지 않는 경우 동의를 거부하거나 가입 해지(회원탈퇴)를 요청할 수 있습니다. 단, 개인 정보에서 서비스 운영을 위해 확인이 필요한 정보를 변경할 경우에는 회사의 승인이 이루어질 때까지 서비스 이용이 제한될 수 있습니다.

관리적 조치 : 내부 관리 계획 수립 및 시행, 직원 정기적 교육
기술적 조치 : 개인정보 처리시스템(또는 개인정보가 저장된 컴퓨터)의 비밀번호 설정 등 접근권한 관리, 백신소프트웨어 등 보안프로그램 설치, 개인정보가 저장된 파일의 암호화
물리적 조치 : 개인정보가 저장․보관된 장소의 접근통제

제9조(개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)
1. 회원은 언제든지 등록되어 있는 자신의 개인 정보를 열람하거나 수정할 수 있으며, 회사의 개인정보 처리에 동의하지 않는 경우 동의를 거부하거나 가입 해지(회원탈퇴)를 요청할 수 있습니다. 단, 개인 정보에서 서비스 운영을 위해 확인이 필요한 정보를 변경할 경우에는 회사의 승인이 이루어질 때까지 서비스 이용이 제한될 수 있습니다.

쿠키의 사용목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용 형태, 인기 검색어, 보안 접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.

제10조(개인정보 보호책임자)
넥스트도어는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.

▶ 개인정보 보호책임자
성명: 김태권
연락처: 010-3412-2415 

제 11조 (고지의 의무)
현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 회사 애플리케이션, 서비스 내의 '공지사항' 또는 기타 알기 쉬운 방법을 통해 고지할 것입니다. 다만, 이용자의 소중한 권리 또는 중요한 내용 변경이 발생하는 경우에는 최소 30일 전에 고지합니다.

[개인정보 처리방침의 시행]

시행일자 : 2024.06.10
`,
    },
    {
      title: "돌바유 개인정보 제3자 제공 동의",
      text: `넥스트도어(회사)는 ｢개인정보 보호법｣ 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다. <br/>
제1조(개인정보의 처리목적)
넥스트도어는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 ｢개인정보 보호법｣ 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.<br/>
1. 넥스트도어 서비스 제공 및 운영
회원이 가입한 넥스트도어 서비스 내 상호 식별, 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산, 이용 및 경비 결제, 콘텐츠 제공, 물품 배송 또는 청구서 등 발송, 금융거래 본인 인증 및 금융 서비스(추후 기능 추가 예정), 미수금 추심, 서비스 이용 중 사고 발생 시 사고처리, 회사의 약관 또는 이용 정책의 위반 행위 적발을 위한 기록 조회 및 이와 관련된 사항
2. 회원관리 및 본인확인
서비스 제공, 회원 식별, 본인 확인, 개인 식별, 이용이 제한된 회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인 및 가입 횟수 제한, 분쟁 조정을 위한 기록 보존, 불만 처리 등 민원 처리, 고지 사항 전달, 추후 법정 대리인 본인 확인, 최신 정보 전달, 서비스의 유효성 확인, 접속 빈도 파악, 회원의 서비스 이용에 대한 통계 확인 및 파악 및 이와 관련된 사항
3. 신규 서비스 개발 서비스 개선, 신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공
4. (선택적) 마케팅, 광고에의 활용 고객에게 이벤트 참여 기회 제공 등 마케팅에 이용 또는 고객에게 광고성 정보 제공
5. 사고 발생 시 원인 규명 및 처리를 위한 수집 및 이용
신원확인, 민원사항 확인, 사실조사를 위한 연락통〮지, 처리결과 통보의 목적으로 개인정보를 처리합니다.

제2조(개인정보의 처리 및 보유기간)
1. 넥스트도어는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
2. 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
(1) 회사 내부 방침에 의한 정보 보유
회원 간의 분쟁 및 문제해결을 위한 회원정보 기록 : 분쟁의 소멸 시효 등 도과시까지
탈퇴 회원의 재가입 제한을 위한 회원정보 기록: 3개월
이용 제한 회원 및 부정 회원의 재가입 제한을 위한 회원 정보 기록: 제한 기간에 따름(별도저장)
(2) 관계 법령에 의한 정보 보유
표시/광고에 관한 기록 : 6개월 (「전자상거래 등에서의 소비자보호에 관한 법률」)
계약 또는 청약철회 등에 관한 기록 : 5년 (「전자상거래 등에서의 소비자보호에 관한 법률」)
대금결제 및 재화 등의 공급에 관한 기록 : 5년 (「전자상거래 등에서의 소비자보호에 관한 법률」)
소비자의 불만 또는 분쟁 처리에 관한 기록 : 3년 (「전자상거래 등에서의 소비자보호에 관한 법률」)
로그인 기록 : 3개월 (「통신비밀보호법」)

제3조(개인정보의 제3자 제공)
넥스트도어는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 ｢개인정보 보호법｣ 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
다만, 다음의 경우 주의를 기울여 회원의 개인정보를 제한적으로 공개할 수 있습니다. 돌바유 서비스 이용 도중 매칭된 회원과 헬퍼 상호간 식별을 위해 이름, 프로필 사진, 전화 번호 등이 돌바유 서비스 내의 연결된 회원에게 공개됩니다. 이는 특정이 불가능하여 별도로 고지하여 동의 받지 못함을 양해바랍니다.

제4조(개인정보처리의 위탁)
넥스트도어는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
1. 결제서비스 : 나이스페이먼트 주식회사
넥스트도어는 위탁계약 체결 시 ｢개인정보 보호법｣ 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고있습니다.
2. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.

제5조(정보주체와 법정대리인의 권리·의무 및 행사방법)
1. 회원은 언제든지 등록되어 있는 자신의 개인 정보를 열람하거나 수정할 수 있으며, 회사의 개인정보 처리에 동의하지 않는 경우 동의를 거부하거나 가입 해지(회원탈퇴)를 요청할 수 있습니다. 단, 개인 정보에서 서비스 운영을 위해 확인이 필요한 정보를 변경할 경우에는 회사의 승인이 이루어질 때까지 서비스 이용이 제한될 수 있습니다.
2. 회원의 개인정보 조회 및 수정을 위해서는 마이페이지 내 "회원정보수정"을, 가입해지(동의철회)를 위해서는 "회원탈퇴"를, 각 클릭하여 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보 관리책임자에게 서면 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
3. 회원이 개인정보의 오류에 대한 정정을 요청하신 경우에 회사는 당해 정정을 완료하기 전까지 당해 개인정보를 이용하거나 제3자에게 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리 결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
4. 회사는 회원의 요청에 의해 해지 또는 삭제된 개인정보는 "제2조 개인정보의 처리 및 보유기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.

제6조(처리하는 개인정보 항목)
1. 수집하는 개인정보의 항목
회사는 회원가입, 헬퍼의 신분 확인, 그리고 각종 서비스의 제공을 위해 돌바유 서비스 최초 이용 시 다음과 같은 개인정보를 수집하고 있습니다.
일반 사용자 개인정보 : 이름, 성별, 연락처(휴대전화번호), 생년월일, 통신사업자, 암호화된 이용자 확인값(CI), 중복가입확인정보(DI), 서비스 이용 및 결제내역, 유료 서비스 이용 시 신용카드 정보, 신용카드 번호 일부 등
헬퍼 사용자 개인정보 : 이름, 성별, 연락처(휴대전화번호), 생년월일, 주민등록번호, 통신사업자, 암호화된 이용자 확인값(CI), 중복가입확인정보(DI), 프로필 이미지, 직업, 서비스 지역, 서비스 이용료 정산을 위한 계좌 정보(은행명,예금주명,계좌번호), 자격증, 학생증정보(또는 재학증명서)
기타 정보 : 쿠키정보, 헬퍼 위치 정보

제7조(개인정보의 파기)
1. 넥스트도어는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
2. 개인정보 파기 방법은 다음과 같습니다.
전자적 파일 : 파일 삭제 및 디스크 등의 저장매체 포맷
수기(手記) 문서 : 분쇄하거나 소각

제8조(개인정보의 안전성 확보조치)
회원은 언제든지 등록되어 있는 자신의 개인 정보를 열람하거나 수정할 수 있으며, 회사의 개인정보 처리에 동의하지 않는 경우 동의를 거부하거나 가입 해지(회원탈퇴)를 요청할 수 있습니다. 단, 개인 정보에서 서비스 운영을 위해 확인이 필요한 정보를 변경할 경우에는 회사의 승인이 이루어질 때까지 서비스 이용이 제한될 수 있습니다.

관리적 조치 : 내부 관리 계획 수립 및 시행, 직원 정기적 교육
기술적 조치 : 개인정보 처리시스템(또는 개인정보가 저장된 컴퓨터)의 비밀번호 설정 등 접근권한 관리, 백신소프트웨어 등 보안프로그램 설치, 개인정보가 저장된 파일의 암호화
물리적 조치 : 개인정보가 저장․보관된 장소의 접근통제

제9조(개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)
1. 회원은 언제든지 등록되어 있는 자신의 개인 정보를 열람하거나 수정할 수 있으며, 회사의 개인정보 처리에 동의하지 않는 경우 동의를 거부하거나 가입 해지(회원탈퇴)를 요청할 수 있습니다. 단, 개인 정보에서 서비스 운영을 위해 확인이 필요한 정보를 변경할 경우에는 회사의 승인이 이루어질 때까지 서비스 이용이 제한될 수 있습니다.

쿠키의 사용목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용 형태, 인기 검색어, 보안 접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.

제10조(개인정보 보호책임자)
넥스트도어는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.

▶ 개인정보 보호책임자
성명: 김태권
연락처: 010-3412-2415 

제 11조 (고지의 의무)
현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 회사 애플리케이션, 서비스 내의 '공지사항' 또는 기타 알기 쉬운 방법을 통해 고지할 것입니다. 다만, 이용자의 소중한 권리 또는 중요한 내용 변경이 발생하는 경우에는 최소 30일 전에 고지합니다.

[개인정보 처리방침의 시행]

시행일자 : 2024.06.10
`,
    },
    {
      title: "마케팅정보 수신 동의",
      text: `개인정보보호법 제 22조 제4항에 의해 선택정보 사항에 대해서는 기재하지 않으셔도 서비스를 이용하실 수 있습니다.
<ul>
<li>
마케팅 및 광고에의 활용
신규 서비스 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
</li>
<li>넥스트도어는 서비스를 운영함에 있어 각종 정보를 서비스 화면, 전화, e-mail, 문자, 우편물, 앱푸시 등의 방법으로 회원에게 제공할 수 있으며, 쿠폰 및 적립금 사용 등 의무적으로 안내되어야 하는 정보성 내용은 수신동의 여부와 무관하게 제공합니다.</li>
</ul>`,
    },
    {
      title: "구매 약관",
      text: `제1조 (목적)
이 약관은 NICE페이먼츠 주식회사(이하 '회사'라 합니다)가 제공하는 전자지급결제대행서비스 및 결제대금예치서비스를 이용자가 이용함에 있어 회사와 이용자 사이의 전자금융거래에 관한 기본적인 사항을 정함을 목적으로 합니다.

제2조 (용어의 정의)
이 약관에서 정하는 용어의 정의는 다음과 같습니다.
• '전자금융거래'라 함은 회사가 전자적 장치를 통하여 전자지급결제대행서비스 및 결제대금예치서비스(이하 '전자금융거래 서비스'라고 합니다)를 제공하고, 이용자가 회사의 종사자와 직접 대면하거나 의사소통을 하지 아니하고 자동화된 방식으로 이를 이용하는 거래를 말합니다.
• '전자지급결제대행서비스'라 함은 전자적 방법으로 재화의 구입 또는 용역의 이용에 있어서 지급결제정보를 송신하거나 수신하는 것 또는 그 대가의 정산을 대행하거나 매개하는 서비스를 말합니다.
• '결제대금예치서비스'라 함은 이용자가 재화의 구입 또는 용역의 이용에 있어서 그 대가(이하 '결제대금'이라 한다)의 전부 또는 일부를 재화 또는 용역(이하 '재화 등'이라 합니다)을 공급받기 전에 미리 지급하는 경우, 회사가 이용자의 물품수령 또는 서비스 이용 확인 시점까지 결제대금을 예치하는 서비스를 말합니다.
• '이용자'라 함은 이 약관에 동의하고 회사가 제공하는 전자금융거래 서비스를 이용하는 자를 말합니다.
• '접근매체'라 함은 전자금융거래에 있어서 거래지시를 하거나 이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 수단 또는 정보로서 전자식 카드 및 이에 준하는 전자적 정보(신용카드번호를 포함한다), '전자서명법'상의 인증서, 회사에 등록된 이용자번호, 이용자의 생체정보, 이상의 수단이나 정보를 사용하는데 필요한 비밀번호 등 전자금융거래법 제2조 제10호에서 정하고 있는 것을 말합니다.
• '거래지시'라 함은 이용자가 본 약관에 의하여 체결되는 전자금융거래계약에 따라 회사에 대하여 전자금융거래의 처리를 지시하는 것을 말합니다.
• '오류'라 함은 이용자의 고의 또는 과실 없이 전자금융거래가 전자금융거래계약 또는 이용자의 거래지시에 따라 이행되지 아니한 경우를 말합니다.

제3조 (약관의 명시 및 변경)
• 회사는 이용자가 전자금융거래 서비스를 이용하기 전에 이 약관을 게시하고 이용자가 이 약관의 중요한 내용을 확인할 수 있도록 합니다.
• 회사는 이용자의 요청이 있는 경우 전자문서의 전송방식에 의하여 본 약관의 사본을 이용자에게 교부합니다.
• 회사가 약관을 변경하는 때에는 그 시행일 1월 전에 변경되는 약관을 회사가 제공하는 전자금융거래 서비스 이용 초기화면 및 회사의 홈페이지에 게시함으로써 이용자에게 공지합니다.
• 회사는 제3항의 공지를 할 경우 "이용자가 변경에 따라 변경에 동의하지 아니한 경우 공지 받은 날로부터 30일 이내에 계약을 해지할 수 있으며, 해지의사표시를 하지 아니한 경우 동의한 것으로 본다."라는 내용을 통지합니다.

제4조 (전자지급결제대행서비스의 종류)
회사가 제공하는 전자지급결제대행서비스는 지급결제수단에 따라 다음과 같이 구별됩니다.
• 신용카드결제대행서비스: 이용자가 결제대금의 지급을 위하여 제공한 지급결제수단이 신용카드인 경우로서, 회사가 전자결제시스템을 통하여 신용카드 지불정보를 송, 수신하고 결제대금의 정산을 대행하거나 매개하는 서비스를 말합니다.
• 계좌이체결제대행서비스: 이용자가 결제대금을 회사의 전자결제시스템을 통하여 금융기관에 등록한 자신의 계좌에서 출금하여 원하는 계좌로 이체할 수 있는 실시간 송금 서비스를 말합니다.
• 가상계좌결제대행서비스: 이용자가 결제대금을 현금으로 결제하고자 경우 회사의 전자결제시스템을 통하여 자동으로 이용자만의 고유한 일회용 계좌의 발급을 통하여 결제대금의 지급이 이루어지는 서비스를 말합니다.
• 간편결제서비스 : 이용자가 결제대금의 지급을 위하여 제공한 지급결제수단이 신용카드, 계좌이체인 경우로서, 정보를 매번 입력할 필요 없이 관련 정보의 한번 등록만으로 상품 결제가 가능한 서비스를 말합니다. 단, 간편결제서비스 신청 시 회사가 정하는 이용자의 본인확인 절차가 반드시 필요하며, 회사의 인증 및 승낙이 있어야 서비스를 이용할 수 있습니다.
• 기타: 회사가 제공하는 서비스로서 지급결제수단의 종류에 따라 '휴대폰 결제대행서비스', '상품권결제대행서비스', 등이 있습니다.

제5조 (결제대금예치서비스의 내용)
• 이용자(이용자의 동의가 있는 경우에는 재화 등을 공급받을 자를 포함합니다. 이하 본 조에서 같습니다)는 재화 등을 공급받은 사실을 재화 등을 공급받은 날부터 3영업일 이내에 회사에 통보하여야 합니다.
• 회사는 이용자로부터 재화 등을 공급받은 사실을 통보 받은 후 회사와 통신판매업자간 사이에서 정한 기일 내에 통신판매업자에게 결제대금을 지급합니다.
• 회사는 이용자가 재화 등을 공급받은 날부터 3영업일이 지나도록 정당한 사유의 제시 없이 그 공급받은 사실을 회사에 통보하지 아니하는 경우에는 이용자의 동의 없이 통신판매업자에게 결제대금을 지급할 수 있습니다.
• 회사는 통신판매업자에게 결제대금을 지급하기 전에 이용자에게 결제대금을 환급 받을 사유가 발생한 경우에는 그 결제대금을 소비자에게 환급합니다.
• 회사는 이용자와의 결제대금예치서비스 이용과 관련된 구체적인 권리, 의무를 정하기 위하여 본 약관과는 별도로 결제대금예치서비스이용약관을 제정할 수 있습니다.

제6조 (이용시간)
• 회사는 이용자에게 연중무휴 1일 24시간 전자금융거래 서비스를 제공함을 원칙으로 합니다. 단, 금융기관 기타 결제수단 발행업자의 사정에 따라 달리 정할 수 있습니다. 계좌이체결제대행서비스, 가상계좌결제대행서비스, 간편결제서비스는 은행사의 사정에 따라 “00:30~ 23:30” 까지 전자금융거래 서비스를 제공함을 원칙으로 합니다.
• 회사는 정보통신설비의 보수, 점검 기타 기술상의 필요나 금융기관 기타 결제수단 발행업자의 사정에 의하여 서비스 중단이 불가피한 경우, 서비스 중단 3일 전까지 게시 가능한 전자적 수단을 통하여 서비스 중단 사실을 게시한 후 서비스를 일시 중단할 수 있습니다. 다만, 시스템 장애복구, 긴급한 프로그램 보수, 외부요인 등 불가피한 경우에는 사전 게시 없이 서비스를 중단할 수 있습니다.

제7조 (접근매체의 선정과 사용 및 관리)
• 회사는 전자금융거래 서비스 제공 시 접근매체를 선정하여 이용자의 신원, 권한 및 거래지시의 내용 등을 확인할 수 있습니다.
• 이용자는 접근매체를 제3자에게 대여하거나 사용을 위임하거나 양도, 양수 또는 담보 목적으로 제공할 수 없습니다.
• 이용자는 자신의 접근매체를 제3자에게 누설 또는 노출하거나 방치하여서는 안되며, 접근매체의 도용이나 위조 또는 변조를 방지하기 위하여 충분한 주의를 기울여야 합니다.
• 회사는 이용자로부터 접근매체의 분실이나 도난 등의 통지를 받은 때에는 그 때부터 제3자가 그 접근매체를 사용함으로 인하여 이용자에게 발생한 손해를 배상할 책임이 있습니다.

제8조 (거래내용의 확인)
• 회사는 이용자와 미리 약정한 전자적 방법을 통하여 이용자의 거래내용(이용자의 '오류정정 요구사실 및 처리결과에 관한 사항'을 포함합니다)을 확인할 수 있도록 하며, 이용자의 요청이 있는 경우에는 요청을 받은 날로부터 2주 이내에 모사전송 등의 방법으로 거래내용에 관한 서면을 교부합니다.
• 회사는 제1호에 따른 이용자의 거래내용 서면교부 요청을 받은 경우 전자적 장치의 운영장애, 그 밖의 사유로 거래내용을 제공할 수 없는 때에는 즉시 이용자에게 전자문서 전송(전자우편을 이용한 전송을 포함합니다)의 방법으로 그러한 사유를 알려야 하며, 전자적 장치의 운영장애 등의 사유로 거래내용을 제공할 수 없는 기간은 제1호의 거래내용에 관한 서면의 교부기간에 산입하지 아니합니다.
• 회사가 이용자에게 제공하는 거래내용 중 거래계좌의 명칭 또는 번호, 거래의 종류 및 금액, 거래상대방을 나타내는 정보, 거래일자, 전자적 장치의 종류 및 전자적 장치를 식별할 수 있는 정보와 해당 전자금융거래와 관련한 전자적 장치의 접속기록, 회사가 전자금융거래의 대가로 받은 수수료, 이용자의 출금 동의에 관한 사항, 전자금융거래의 신청 및 조건의 변경에 관한 사항, 건당 거래금액이 1만원을 초과하는 전자금융거래에 관한 기록은 5년간, 건당 거래금액이 1만원 이하인 소액 전자금융거래에 관한 기록, 전자지급수단 이용 시 거래승인에 관한 기록, 이용자의 오류정정 요구사실 및 처리결과에 관한 사항은 1년간의 기간을 대상으로 하되, 회사가 전자지급결제대행 서비스 제공의 대가로 수취한 수수료에 관한 사항은 제공하는 거래내용에서 제외됩니다.
• 이용자가 제1항에서 정한 서면교부를 요청하고자 할 경우 다음의 주소 및 전화번호로 요청할 수 있습니다.
    ◦ 주소: 서울시 마포구 마포대로217(아현동) 크레디트센터
    ◦ 이메일 주소: nicepay@nicepay.co.kr
    ◦ 전화번호: 1661-0808 / 1661-7335
    ◦ FAX: 02-312-3591

제9조 (오류의 정정 등)
• 이용자는 전자금융거래 서비스를 이용함에 있어 오류가 있음을 안 때에는 회사에 대하여 그 정정을 요구할 수 있습니다.
• 회사는 전항의 규정에 따른 오류의 정정요구를 받은 때에는 이를 즉시 조사하여 처리한 후 정정요구를 받은 날부터 2주 이내에 그 결과를 이용자에게 알려 드립니다.

제10조 (회사의 책임)
• 회사는 접근매체의 위조나 변조로 발생한 사고로 인하여 이용자에게 발생한 손해에 대하여 배상책임이 있습니다. 다만 이용자가 제7조 제2항에 위반하거나 제3자가 권한 없이 이용자의 접근매체를 이용하여 전자금융거래를 할 수 있음을 알았거나 알 수 있었음에도 불구하고 이용자가 자신의 접근매체를 누설 또는 노출하거나 방치한 경우 그 책임의 전부 또는 일부를 이용자가 부담하게 할 수 있습니다.
• 회사는 계약체결 또는 거래지시의 전자적 전송이나 처리과정에서 발생한 사고로 인하여 이용자에게 그 손해가 발생한 경우에는 그 손해를 배상할 책임이 있습니다. 다만 본 조 제1항 단서에 해당하거나 법인('중소기업기본법' 제2조 제2항에 의한 소기업을 제외합니다)인 이용자에게 손해가 발생한 경우로서 회사가 사고를 방지하기 위하여 보안절차를 수립하고 이를 철저히 준수하는 등 합리적으로 요구되는 충분한 주의의무를 다한 경우 그 책임의 전부 또는 일부를 이용자가 부담하게 할 수 있습니다.
• 회사는 전자금융거래를 위한 전자적 장치 또는 ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’ 제2조제1항제1호에 따른 정보통신망에 침입하여 거짓이나 그 밖의 부정한 방법으로 획득한 접근매체의 이용으로 발생한 사고로 인하여 이용자에게 그 손해가 발생한 경우에는 그 손해를 배상할 책임이 있습니다.다만, 다음과 같은 경우 회사는 이용자에 대하여 일부 또는 전부에 대하여 책임을 지지 아니합니다. 가. 회사가 접근매체에 따른 확인 외에 보안강화를 위하여 전자금융거래 시 요구하는 추가적인 보안조치를 이용자가 정당한 사유 없이 거부하여 전자금융거래법 제9조 제1항 제3호에 따른(이하 '사고'라 한다) 사고가 발생한 경우 나. 이용자가 동항 제 가목의 추가적인 보안조치에서 사용되는 매체, 수단 또는 정보에 대하여 다음과 같은 행위를 하여 '사고'가 발생하는 경우   - 누설, 누출 또는 방치한 행위   - 3자에게 대여하거나 그 사용을 위임한 행위 또는 양도나 담보의 목적으로 제공한 행위

제11조 (전자지급거래계약의 효력)
• 회사는 이용자의 거래지시가 전자지급거래에 관한 경우 그 지급절차를 대행하며, 전자지급거래에 관한 거래지시의 내용을 전송하여 지급이 이루어지도록 합니다.
• 회사는 이용자의 전자지급거래에 관한 거래지시에 따라 지급거래가 이루어지지 않은 경우 수령한 자금을 이용자에게 반환하여야 합니다.

제12조 (거래지시의 철회)
• 이용자가 전자금융거래를 한 경우, 이용자는 지급의 효력이 발생하기 전까지 본 약관 제8조 제4항 기재 담당자에게 전자문서의 전송(전자우편을 이용한 전송을 포함합니다)에 의한 방법으로 거래지시를 철회할 수 있습니다.
• 전항의 지급의 효력이 발생 시점이란 (i) 전자자금이체의 경우에는 거래 지시된 금액의 정보에 대하여 수취인의 계좌가 개설되어 있는 금융기관의 계좌 원장에 입금기록이 끝난 때 (ii) 그 밖의 전자지급수단으로 지급하는 경우에는 거래 지시된 금액의 정보가 수취인의 계좌가 개설되어 있는 금융기관의 전자적 장치에 입력이 끝난 때를 말합니다.
• 이용자는 지급의 효력이 발생한 경우에는 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령상 청약의 철회의 방법 또는 본 약관 제5조에서 정한 바에 따라 결제대금을 반환 받을 수 있습니다.

제13조 (전자금융거래 기록의 생성 및 보존)
• 회사는 이용자가 전자금융거래의 내용을 추적, 검색하거나 그 내용에 오류가 발생한 경우에 이를 확인하거나 정정할 수 있는 기록을 생성하여 보존합니다.
• 전항의 규정에 따라 회사가 보존하여야 하는 기록의 종류 및 보존방법은 제8조 제3항에서 정한 바에 따릅니다.

제14조 (전자금융거래정보의 제공금지)
회사는 전자금융거래 서비스를 제공함에 있어서 취득한 이용자의 인적 사항, 이용자의 계좌, 접근매체 및 전자금융거래의 내용과 실적에 관한 정보 또는 자료를 금융실명법 등 법령에 의하거나 이용자의 동의를 얻지 아니하고 제3자에게 제공, 누설하거나 업무상 목적 외에 사용하지 아니합니다.

제15조 (분쟁처리 및 분쟁조정)
• 이용자는 다음의 분쟁처리 책임자 및 담당자에 대하여 전자금융거래 서비스 이용과 관련한 의견 및 불만의 제기, 손해배상의 청구 등의 분쟁처리를 요구할 수 있습니다.
    ◦ 담당자: RM팀
    ◦ 연락처(전화번호, 전자우편주소): 1661-0808 / 1661-7335, rm@nicepay.co.kr
• 이용자가 회사에 대하여 분쟁처리를 신청한 경우에는 회사는 15일 이내에 이에 대한 조사 또는 처리 결과를 이용자에게 안내합니다.
• 이용자는 '금융위원회의 설치 등에 관한 법률' 제51조의 규정에 따른 금융감독원의 금융분쟁조정위원회나 '소비자기본법' 제33조 제1항의 규정에 따른 소비자보호원에 회사의 전자금융거래 서비스의 이용과 관련한 분쟁조정을 신청할 수 있습니다.

제16조 (회사의 안정성 확보 의무)
회사는 전자금융거래가 안전하게 처리될 수 있도록 선량한 관리자로서의 주의를 다하며, 전자금융거래의 안전성과 신뢰성을 확보할 수 있도록 전자금융거래의 종류별로 전자적 전송이나 처리를 위한 인력, 시설, 전자적 장치 등의 정보기술부문 및 전자금융업무에 관하여 금융위원회가 정하는 기준을 준수합니다.

제17조 (약관 외 준칙)
• 회사와 이용자 사이에 개별적으로 합의한 사항이 이 약관에 정한 사항과 다를 때에는 그 합의사항을 이 약관에 우선하여 적용합니다.
• 전자금융거래에 관하여 이 약관에 정하지 않은 사항은 개별약관이 정하는 바에 따릅니다.
• 이 약관과 전자금융거래에 관한 개별약관에 정하지 않은 사항(용어의 정의 포함)에 대하여는 다른 합의사항이 없으면 전자금융거래법, 전자상거래 등에서의 소비자 보호에 관한 법률, 여신전문금융업법 등 관계 법령에서 정한 바에 따릅니다.

제18조 (관할)
회사와 이용자간에 발생한 분쟁에 관한 관할은 민사소송법에서 정한 바에 따릅니다.

부칙
최초 시행일자 : 2016년 7월 17일
변경 공고일자 : 2020년 7월 1일
변경 시행일자 : 2020년 8월 3일`,
    },
  ];

  if (modal === null) return null;

  return (
    <ModalWrapper position="full" onClose={handleClose}>
      <Wrapper>
        <Header>
          <Close onClick={handleClose} />
        </Header>
        <PopupText>
          <h1
            dangerouslySetInnerHTML={{
              __html: String(arr[Number(modal?.type)]?.title),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: String(arr[Number(modal?.type)]?.text),
            }}
          />
        </PopupText>
      </Wrapper>
    </ModalWrapper>
  );
};

export default TermModal;

const PopupText = styled.div`
  width: 100%;
  height: calc(100% - 54px);
  overflow: auto;
  padding: 0 20px 20px;

  & > p {
    font-size: 12px;
    font-weight: 400;
    line-height: 21.6px;
    letter-spacing: -0.02em;
    color: black;
    white-space: pre-wrap;

    & > ol {
      list-style: decimal;
      padding: 14px;
    }
  }

  & > h1 {
    font-size: 22px;
    font-weight: 500;
    line-height: 30.8px;
    letter-spacing: -0.2px;
    margin-bottom: 30px;
    color: ${(props) => props.theme.color.gray1};
  }
`;

const Header = styled.div`
  width: 100%;
  height: 54px;
  padding: 15px 20px;

  & > svg {
    display: block;
    margin-left: auto;
    width: 24px;
    height: 24px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
`;
