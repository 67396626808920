import loadable from "@loadable/component";

export const Root = loadable(() => import("./Root"));

export const Kakao = loadable(() => import("./appLogin/Kakao"));
export const Apple = loadable(() => import("./appLogin/Apple"));

export const Login = loadable(() => import("./user/Login"));
export const PhoneLogin = loadable(() => import("./user/Phone"));
export const PhoneCertified = loadable(() => import("./user/Certified"));
export const Join = loadable(() => import("./user/Term"));
export const JoinInfo = loadable(() => import("./user/JoinInfo"));

export const Main = loadable(() => import("./main/Main"));
export const MainBanner = loadable(() => import("./main/MainBanner"));

export const HelpIntro = loadable(() => import("./help/HelpIntro"));
export const HelpForm = loadable(() => import("./help/HelpForm"));
export const Matching = loadable(() => import("./help/Matching"));
export const Helper = loadable(() => import("./help/Helper"));
export const Payment = loadable(() => import("./help/Payment"));

export const UsageList = loadable(() => import("./usage/List"));
export const UsageDetail = loadable(() => import("./usage/Detail"));
export const Chat = loadable(() => import("./usage/Chat"));
export const Location = loadable(() => import("./usage/Location"));
export const Review = loadable(() => import("./usage/Review"));
export const Report = loadable(() => import("./usage/Report"));

export const Mypage = loadable(() => import("./mypage/Mypage"));
export const Term = loadable(() => import("./mypage/Term"));
export const Profile = loadable(() => import("./mypage/Profile"));
export const Modify = loadable(() => import("./mypage/Modify"));
export const ModifyCertified = loadable(
  () => import("./mypage/ModifyCertified")
);
export const Change = loadable(() => import("./mypage/Change"));
export const Withdrawal = loadable(() => import("./mypage/Withdrawal"));
export const Coupon = loadable(() => import("./mypage/Coupon"));
export const Faq = loadable(() => import("./mypage/Faq"));
export const Setting = loadable(() => import("./mypage/Setting"));
