const color = {
  main: "#F2B705",
  sub: "#6C9EF6",
  sub3: "#2E7AE7",
  red: "#FF9797",

  line: "#424243",

  gray1: "#1E1E1E",
  gray2: "#020101",
  gray3: "#0D0D0E",
  gray4: "#e1e1e2",
  gray5: "#C7C7C7",
  gray6: "#5E5E5E",
  gray7: "#707070",
  gray8: "#ACACAC",
  gray9: "#1C1E1D",
  gray10: "#3B3B40",
  gray11: "#7C7C80",
  gray12: "#B0B0B0",
  gray13: "#363A39",
  gray14: "#7D807F",
};

export type ColorType = typeof color;

export default { color };
