import { api } from "./api";

const fetcher = async (url: string) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
  };

  const res = await api.request(config);

  return res.data.data || res.data;
};

const swrConfig = {
  fetcher,
  revalidateOnFocus: false,
  errorRetryCount: 0,
};

export default swrConfig;
